import {
  _dispatch,
  MENU_LIST_SUCCESS,
  MENU_CATEGORY_SUCCESS,
  REQUESTED_ITEM_LIST_SUCCESS,
  UPDATE_REQUESTED_ITEM_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  BRAND_LIST_SUCCESS,
  DELETE_BRANDS_SUCCESS,
  DELETE_MENU_CATEGORY_SUCCESS,
  TAGS_LIST_SUCCESS,
  ADD_TAGS_SUCCESS,
  UPDATE_TAGS_SUCCESS,
  TAGS_DELETE_SUCCESS
} from '../actions/menus';

const INITIAL_STATE = {status: null, menu_list: [], menu_category:[], requested_item_list:[], brandList: {}, tagsList: {} };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case MENU_LIST_SUCCESS:
      return _dispatch({ ...state, menu_list: action.payload}, true, 'menuFullList');
      break;
    case MENU_CATEGORY_SUCCESS:
      return _dispatch({ ...state, menu_category: action.payload}, true, 'menuCategory');
      break;
    case REQUESTED_ITEM_LIST_SUCCESS:
      return _dispatch({ ...state, requested_item_list: action.payload, activePage: action.activePage}, true, 'requesteditemlist');
      break;
    case UPDATE_REQUESTED_ITEM_SUCCESS:
      return (() => {
        const data = {...state.requested_item_list};
        data.items.splice(action.payload, 1);
        return _dispatch({ ...state, requested_item_list: data}, true, 'requesteditemlist');
      })();
      break;
    case DELETE_MENU_ITEM_SUCCESS:
      return _dispatch({ ...state, menu_list: action.payload}, true, 'menuFullList');
      break;
    case BRAND_LIST_SUCCESS:
      return _dispatch({ ...state, brandList: action.payload}, true, 'brandlist');
      break;
    case DELETE_BRANDS_SUCCESS:
      return (() => {
        const data = {...state.brandList};
        data.brand.splice(action.payload, 1);
        return _dispatch({ ...state, brandList: data}, true, 'brandlist');
      })();
      break;
    case DELETE_MENU_CATEGORY_SUCCESS:
      return (() => {
        const data = {...state.menu_category};
        data.category.splice(action.payload, 1);
        return _dispatch({ ...state, menu_category: data}, true, 'menuCategory');
      })();
      break;

    case TAGS_LIST_SUCCESS:
      return _dispatch({ ...state, tagsList: action.payload}, true, 'tag_list');
      break;
    case ADD_TAGS_SUCCESS:
      return (() => {
        return _dispatch({ ...state, tagsList: [action.payload, ...state.tagsList]}, true, 'tag_list');
      })()
      break;

    case UPDATE_TAGS_SUCCESS:
      return (() => {
        const tags = action.payload;
        const itemIndex = action.index;
        const tagsList = {...state.tagsList};
        tagsList.tags.map((item, index) => {
          if(index == itemIndex){
            tagsList[index].name = tags.name;
          }
        });
        return _dispatch({ ...state, tagsList}, true, 'tag_list');
      })();
      break;
    case TAGS_DELETE_SUCCESS:
      return (() => {
        const data = {...state.tagsList};
        data.tags.splice(action.payload, 1);
        return _dispatch({ ...state, tagsList: data}, true, 'tag_list');
      })();
      break;


    default:
      return state;
  }
}