import {
  _dispatch,
  DELIVERY_LIST_SUCCESS,
  DELETE_DELIVERY_RULE_SUCCESS
} from '../actions/settings';

const INITIAL_STATE = {status: null, delivery_list: []};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case DELIVERY_LIST_SUCCESS:
      return _dispatch({ ...state, delivery_list: action.payload}, true, 'deliverysetting');
      break;
    case DELETE_DELIVERY_RULE_SUCCESS:
      return (() => {
        const data = [...state.delivery_list];
        data.splice(action.payload, 1);
        return _dispatch({ ...state, delivery_list: data}, true, 'deliverysetting');
      })();
    default:
      return state;
  }
}