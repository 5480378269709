import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const RECOMMENDED_ITEM_SUCCESS = 'RECOMMENDED_ITEM_SUCCESS';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_CATEGORY_SUCCESS = 'MENU_CATEGORY_SUCCESS';
export const ADD_MENU_ITEM_SUCCESS = 'ADD_MENU_ITEM_SUCCESS';
export const ADD_MENU_CATEGORY_SUCCESS = 'ADD_MENU_CATEGORY_SUCCESS';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const CATEGORY_DETAIL_SUCCESS = 'CATEGORY_DETAIL_SUCCESS';
export const MENU_ITEM_DETAIL_SUCCESS = 'MENU_ITEM_DETAIL_SUCCESS';
export const UPDATE_MENU_CATEGORY_ORDER_SUCCESS = 'UPDATE_MENU_CATEGORY_ORDER_SUCCESS';
export const REQUESTED_ITEM_LIST_SUCCESS = 'REQUESTED_ITEM_LIST_SUCCESS';
export const UPDATE_REQUESTED_ITEM_SUCCESS = 'UPDATE_REQUESTED_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS';
export const DELETE_BRANDS_SUCCESS = 'DELETE_BRANDS_SUCCESS';
export const DELETE_MENU_CATEGORY_SUCCESS = 'DELETE_MENU_CATEGORY_SUCCESS';
export const TAGS_LIST_SUCCESS = 'TAGS_LIST_SUCCESS';
export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';
export const UPDATE_TAGS_SUCCESS = 'UPDATE_TAGS_SUCCESS';
export const TAGS_DELETE_SUCCESS = 'TAGS_DELETE_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function getRecommendedItems(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getitemrecommended`,
    
  });
}

export function getRecommendedItemsSuccess(payload){
  return {
      type: RECOMMENDED_ITEM_SUCCESS,
      payload: payload
    }
}

export function menuListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getItems`,
    
  });
}

export function menuListingSuccess(payload){
  return {
      type: MENU_LIST_SUCCESS,
      payload: payload
    }
}

export function menuCategory(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getGlobalCategories`,
    
  });
}

export function menuCategorySuccess(payload){
  return {
      type: MENU_CATEGORY_SUCCESS,
      payload: payload
    }
}

export function addMenuItems(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/grocery/addItems`
  });
}

export function addMenuItemsSuccess(payload) {
  return {
    type: ADD_MENU_ITEM_SUCCESS,
    payload: payload
  };
}

export function deleteMenuItem(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/deletemenuitem`
  });
}

export function deleteMenuItemSuccess(payload){
  return {
      type: DELETE_MENU_ITEM_SUCCESS,
      payload: payload
    }
}

export function addMenuCategories(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/grocery/addGlobalCategories`
  });
}

export function addMenuCategoriesSuccess(payload) {
  return {
    type: ADD_MENU_CATEGORY_SUCCESS,
    payload: payload
  };
}

export function deleteMenuCategory(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/deleteCategory`
  });
}

export function deleteMenuCategorySuccess(payload){
  return {
      type: DELETE_MENU_CATEGORY_SUCCESS,
      payload: payload
    }
}

export function categoryDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getGlobalCategorieById`,
  });
}

export function categoryDetailByIdSuccess(payload) {
  return {
    type: CATEGORY_DETAIL_SUCCESS,
    payload: payload
  };
}

export function menuItemDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getItemById`,
  });
}

export function menuItemDetailByIdSuccess(payload) {
  return {
    type: MENU_ITEM_DETAIL_SUCCESS,
    payload: payload
  };
}

export function statusItemAvailability(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/markitemavailability`,
  });
}

export function updateMenuCategoryOrder(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/changecategoryposition`
  });
}

export function requestedItemListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getunapproveditems`,
  });
}

export function requestedItemListingSuccess(payload, activePage){
  return {
      type: REQUESTED_ITEM_LIST_SUCCESS,
      payload: payload,
      activePage
    }
}

export function updateRequestedItemStatus(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/acceptrejectitem`
  });
}

export function updateRequestedItemStatusSuccess(payload) {
  return {
    type: UPDATE_REQUESTED_ITEM_SUCCESS,
    payload: payload
  };
}

export function getItemRejectedReasonList(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getrejectreasons`,
  });
}
export function changeItemPosition(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/changeitemposition`
  });
}
export function addItemInfoCsv(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/additeminfocsv`
  });
}
export function addItemVariantCsv(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/additemvariantcsv`
  });
}
export function addItemAddonCsv(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/additemaddoncsv`
  });
}
export function getShopCategoryCsv(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/makeVenderCsv`
  });
}
export function getItemReporting(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/getitemreporting`,
  });
}

export function deleteMenuMultipleItems(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/deleteallmenuitem`,
  });
}

export function brandListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getBrand`,
  });
}

export function brandListingSuccess(payload, activePage){
  return {
    type: BRAND_LIST_SUCCESS,
    payload: payload,
    activePage
  }
}
export function deleteBrands(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/deleteBrands`
  });
}
export function deleteBrandsSuccess(payload) {
  return {
    type: DELETE_BRANDS_SUCCESS,
    payload: payload
  };
}
export function brandDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getBrandById`,
  });
}
export function addBrand(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/grocery/addBrands`
  });
}
export function tagsListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getTags`,
  });
}

export function tagsListingSuccess(payload){
  return {
      type: TAGS_LIST_SUCCESS,
      payload: payload
    }
}
export function addTags(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/serviceprovider/grocery/saveTags`
  });
}

export function addTagsSuccess(payload) {
  return {
    type: ADD_TAGS_SUCCESS,
    payload: payload
  };
}

export function getTagsById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getTagsById`,
  });
}

export function updateTagsSuccess(payload, index) {
  return {
    type: UPDATE_TAGS_SUCCESS,
    payload: payload,
    index: index
  };
}

export function deleteTags(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/deleteTags`
  });
}

export function deleteTagsSuccess(payload) {
  return {
    type: TAGS_DELETE_SUCCESS,
    payload: payload
  };
}

export function getParentCategoty(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getParentCategoty`,
  });
}

export function getSubCategoriesByParentId(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getSubCategoriesByParentId`,
  });
}

export function getUnitType(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getUnitType`,
  });
}

export function getItemCsv(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/serviceprovider/grocery/getItemCsv`,
  });
}

export function updateItemPrice(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/serviceprovider/grocery/updateItemPrice`
  });
}




